/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,500&display=swap');
html {
    scroll-behavior: smooth;
}
body {
    
    background-color: rgba(0, 0, 0, 0.1);
}
